import { ComponentType, ControllerType } from './types';

export function getSearchBoxComponentDefinition({ layout }) {
  return {
    componentType: ComponentType.SearchBox,
    layout,
    data: {
      type: 'SearchBox',
    },
    props: {
      type: 'SearchBoxProperties',
    },
    styleId: 'SearchBox_1',
    skin: 'wixui.skins.SearchBox',
    style: {
      skin: 'wixui.skins.SearchBox',
      componentClassName: 'wixui.SearchBox',
      style: {
        properties: {
          'alpha-inputIconColor': '0.6',
          'alpha-inputBorderColor': '0.6',
          'alpha-buttonBackgroundColor': '0.8',
          'boxShadowToggleOn-inputShadow': 'false',
          inputShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.6)',
          inputFont: 'font_8',
        },
        propertiesSource: {
          'alpha-inputIconColor': 'value',
          'alpha-inputBorderColor': 'value',
          'alpha-buttonBackgroundColor': 'value',
          'boxShadowToggleOn-inputShadow': 'value',
          inputShadow: 'value',
          inputFont: 'value',
        },
      },
    },
  };
}

export function getSearchResultsHeaderComponentDefinition({ text, layout }) {
  return {
    componentType: 'wysiwyg.viewer.components.WRichText',
    skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
    layout,
    data: {
      text: `<h1 class="font_0" style="font-size:32px;text-align:center;"><span style="font-size:32px;">${text}</span></h1>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
  };
}

export function getSearchAppControllerComponentDefinition({ appDefinitionId }) {
  return {
    componentType: 'platform.components.AppController',
    data: {
      type: 'AppController',
      controllerType: ControllerType.SearchApp,
      applicationId: appDefinitionId,
      settings: JSON.stringify({}),
    },
    layout: {
      fixedPosition: true,
      docked: {
        bottom: {
          px: 20,
        },
        hCenter: {
          px: 0,
        },
      },
    },
  };
}
