import * as Sentry from '@sentry/browser';
import Experiments from '@wix/wix-experiments';

import { ControllerType, EventType } from './types';
import {
  addSearchBox,
  isSearchBox,
  getAllSearchBoxes,
  initSearchBoxSuggestions,
} from './searchBox';
import {
  addSearchResultsHeader,
  getSearchResultsComponent,
  getSearchResultsPage,
  updateSearchResultsPageData,
} from './searchResults';
import {
  addSearchAppController,
  connectControllerWithSearchBox,
  connectControllerWithSearchResults,
  getSearchAppControllers,
  isComponentConnected,
  connectSearchBox,
} from './searchAppController';

import {
  removeController,
  removeControllerConnectedComponents,
  getControllerConnectedComponents,
  IEditorReadyPayload,
  IEditorSDKWrapper,
  IComponentRef,
  IPageRef,
} from '@wix/platform-editor-helpers';

import { create as createFedopsLogger } from '@wix/fedops-logger';
import { SENTRY_DSN, setSentryContext, withErrorReporting } from './sentry';

let editor: IEditorSDKWrapper;
let experiments: Experiments;

// TODO: review when https://github.com/wix/yoshi/pull/1496 resolved
const ARTIFACT_VERSION = (window as any).__CI_APP_VERSION__;

const fedopsLogger = createFedopsLogger('search-editor-platform');

const enum Interactions {
  EditorReady = 'EditorReady',
  InstallApp = 'InstallApp',
  RemoveApp = 'RemoveApp',
  SearchBoxAdded = 'SearchBoxAdded',
}

Sentry.init({
  dsn: SENTRY_DSN,
  release: ARTIFACT_VERSION,
  enabled: process.env.NODE_ENV === 'production',
  // NOTE: We don't need to report all `window.onerror` events. Manual capturing  is enough
  // https://docs.sentry.io/platforms/javascript/#automatically-capturing-errors-with-promises
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
      onerror: false,
    }),
  ],
});

function isSuggestionExperimentEnabled() {
  return experiments.get('searchSuggestions') === 'new';
}

async function onSearchBoxAdded(componentRef: IComponentRef) {
  if (!(await isComponentConnected(editor, componentRef))) {
    await connectSearchBox(editor, componentRef);
  }

  if (isSuggestionExperimentEnabled()) {
    await initSearchBoxSuggestions(editor, componentRef);
  }
}

async function doFirstInstall() {
  const searchBoxRef = await addSearchBox(editor);

  const [searchResultsRef, searchResultsPageRef] = await Promise.all<
    IComponentRef,
    IPageRef
  >([getSearchResultsComponent(editor), getSearchResultsPage(editor)]);

  await addSearchResultsHeader(editor, {
    searchResultsPageRef,
    searchResultsRef,
  });

  await updateSearchResultsPageData(editor, {
    searchResultsPageRef,
  });

  const searchAppControllerRef = await addSearchAppController(editor);

  await Promise.all([
    connectControllerWithSearchBox(
      editor,
      searchAppControllerRef,
      searchBoxRef,
    ),
    connectControllerWithSearchResults(
      editor,
      searchAppControllerRef,
      searchResultsRef,
    ),
  ]);

  await editor.SDK.pages.navigateTo(editor.token, {
    pageRef: searchResultsPageRef,
  });
  await editor.SDK.selection.selectComponentByCompRef(editor.token, {
    compsToSelect: [searchBoxRef],
  });
}

async function getConnectedSearchBoxes() {
  const controllerRefs = await getSearchAppControllers(editor);
  const connectedSearchBoxes = [];

  for (const controllerRef of controllerRefs) {
    const connectedComponentsRefs = await getControllerConnectedComponents(
      editor,
      controllerRef,
    );

    for (const componentRef of connectedComponentsRefs) {
      if (await isSearchBox(editor, componentRef)) {
        connectedSearchBoxes.push(componentRef);
      }
    }
  }

  return connectedSearchBoxes;
}

async function patchInputFontProperty(componentRef) {
  // patch component style to fix SCALE_UP/SCALE_DOWN GFPP mobile actions. see also:
  //    https://jira.wixpress.com/browse/SER-403
  //    https://github.com/wix-private/site-search/pull/363
  const old = await editor.SDK.components.style.get(editor.token, {
    componentRef,
  });
  const needsInputFont =
    old && old.style && old.style.properties && !old.style.properties.inputFont;

  if (!needsInputFont) {
    return;
  }

  await editor.SDK.components.style.updateFull(editor.token, {
    componentRef,
    style: {
      ...old,
      style: {
        ...old.style,
        properties: {
          ...old.style.properties,
          inputFont: 'font_8',
        },
        propertiesSource: {
          ...old.style.propertiesSource,
          inputFont: 'value',
        },
      },
    },
  });
}

// tslint:disable-next-line:no-shadowed-variable
export const editorReady = withErrorReporting(async function editorReady(
  SDK,
  appDefinitionId: string,
  payload: IEditorReadyPayload,
) {
  fedopsLogger.interactionStarted(Interactions.EditorReady);

  setSentryContext(payload);

  editor = {
    SDK,
    appDefinitionId,
    // NOTE: https://wix.slack.com/archives/C4KPAQ33K/p1564490148055400
    token: appDefinitionId,
  };

  experiments = new Experiments({
    baseUrl: 'https://www.wix.com',
  });

  await experiments.conduct('searchSuggestions', 'old');

  if (payload.firstInstall) {
    fedopsLogger.interactionStarted(Interactions.InstallApp);

    await doFirstInstall();

    fedopsLogger.interactionEnded(Interactions.InstallApp);
  }

  const connectedSearchBoxes = await getConnectedSearchBoxes();
  const allSearchBoxes = await getAllSearchBoxes(editor);

  if (allSearchBoxes.length !== connectedSearchBoxes.length) {
    Sentry.withScope(scope => {
      scope.setTags({
        searchBoxesCount: `${allSearchBoxes.length}`,
        searchBoxesConnectedCount: `${connectedSearchBoxes.length}`,
        searchBoxesUnconnectedCount: `${allSearchBoxes.length -
          connectedSearchBoxes.length}`,
      });

      Sentry.captureMessage(
        `There are more search boxes than connected search boxes`,
      );
    });

    // NOTE: currently SearchBox could not be added without search tpa, so unconnected components means, something goes wrong.
    // connect unconnected SearchBoxes to fix incorrect behaviour.
    // TODO: remove this code when resolve issue SER-328
    const unconnectedSearchBoxes = allSearchBoxes.filter(
      componentRef => !connectedSearchBoxes.includes(componentRef),
    );

    await Promise.all(
      unconnectedSearchBoxes.map(componentRef =>
        connectSearchBox(editor, componentRef),
      ),
    );

    connectedSearchBoxes.push(...unconnectedSearchBoxes);
  }

  await Promise.all(allSearchBoxes.map(patchInputFontProperty));

  if (isSuggestionExperimentEnabled()) {
    await Promise.all(
      connectedSearchBoxes.map(connectedSearchBoxRef =>
        initSearchBoxSuggestions(editor, connectedSearchBoxRef),
      ),
    );
  }

  await editor.SDK.document.application.registerToCustomEvents(editor.token, {
    eventTypes: [EventType.ComponentAddedToStage],
  });

  fedopsLogger.interactionEnded(Interactions.EditorReady);
});

async function onComponentAddedToStage(payload) {
  if (
    // NOTE: uncomment this code when problem with controllers will be resolved
    // payload.appDefinitionId === editor.appDefinitionId &&
    await isSearchBox(editor, payload.compRef)
  ) {
    fedopsLogger.interactionStarted(Interactions.SearchBoxAdded);

    await onSearchBoxAdded(payload.compRef);

    fedopsLogger.interactionEnded(Interactions.SearchBoxAdded);
  }
}

// tslint:disable-next-line:no-shadowed-variable
export const onEvent = withErrorReporting(async function onEvent({
  eventType,
  eventPayload,
}) {
  switch (eventType) {
    case EventType.ComponentAddedToStage: {
      await onComponentAddedToStage(eventPayload);
      break;
    }

    // case EventType.ConnectedComponentPasted:
    default:
  }
});

// tslint:disable-next-line:no-shadowed-variable
export const handleAction = withErrorReporting(async function handleAction({
  type,
}) {
  if (type === 'removeApp') {
    fedopsLogger.interactionStarted(Interactions.RemoveApp);

    const controllerRefs = await getSearchAppControllers(editor);

    for (const controllerRef of controllerRefs) {
      await removeControllerConnectedComponents(editor, controllerRef);
      await removeController(editor, controllerRef);
    }

    await editor.SDK.editor.save();

    fedopsLogger.interactionEnded(Interactions.RemoveApp);
  }
});

export const getAppManifest = () => {
  return Promise.resolve({
    controllersStageData: {
      [ControllerType.SearchApp]: {
        default: {
          visibility: 'NEVER',
        },
      },
    },
  });
};
